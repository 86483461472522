









import { Component, Vue } from 'vue-property-decorator';
import { repertoirTiles } from './repertoirContent';
import TileList from '@/components/shared/TileList.vue';
import { RouterTile } from '@/typings';

@Component({ components: { TileList } })
export default class Repertoir extends Vue {
  repertoirTiles: RouterTile[] = repertoirTiles;
}
